import { AccountType, useUserDataQueryFn } from "../../queries/userData"
import React, { ReactNode } from "react"
import { Box } from "@mui/material"
import OwnerTasks from "../../pages/tasks/OwnerTasks"

interface NoPropertyProps {
    children: ReactNode
}

export default function TicketsOnlyGuard({ children }: NoPropertyProps) {
    const { data: user } = useUserDataQueryFn()

    if (user?.accountType === AccountType.TICKETS_ONLY)
        return (
            <Box display='flex' flexDirection='row' height='100vh' width='100vw'>
                <OwnerTasks />
            </Box>
        )

    return <>{children}</>
}
